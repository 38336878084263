<template>
    <router-link class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-3 p-0 m-0 d-flex justify-content-center align-items-between text-center" :to="link || '#'">
        <div 
            :class="[
                'vehicle-card bg-brand-body-secondary d-flex flex-column justify-content-center align-content-center mt-0 mb-3 mx-2 p-3',
                !vehicleImage && !vehicleModel && 'preloader',
                border && 'border'
            ]"
        >
            <div
                :class="[
                    'vehicle-image',
                    !vehicleImage || !imageLoaded && 'vehicle-image-default-size'
                ]"
            >
                <img
                    :src="vehicleImage"
                    :alt="`Image ${vehicleModel}`"
                    :class="[(!vehicleImage || !imageLoaded) && 'd-none', vehicleImage && 'user-select-none']"
                    width="100"
                    onerror="this.src='https://vvs.autosyncstudio.com/static/not-available-350.png'"
                    :onload="[`this.classList.remove('d-none')`, imageLoaded = true]"
                >
                <div class="d-none preloader">
                    <DotsFlashingLoader/>
                </div>
            </div>
            <div
                :class="[
                    'pb-2 vehicle-model',
                    !vehicleModel && 'vehicle-model-default-size'
                ]"
            >
                <span class="fw-bold">{{vehicleModel}}</span>
            </div>
        </div>
    </router-link>
</template>

<script>
import DotsFlashingLoader from '@/components/shared/DotsFlashingLoader';

export default {
  name: 'VehicleCard',
  components: {
    DotsFlashingLoader
  },
  props: {
    vehicleModel: String,
    vehicleImage: String,
    loaded: {
      type: Boolean,
      default: false
    },
    link: {
      type: String,
      default: null
    },
    border: {
      type: Boolean,
      default: false
    },
  },
  data: function () {
    return {
        imageLoaded: false
    };
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/exportable.scss";

$car-image-width: 350px;
$car-image-height: 183px;

a {
  text-decoration: none;

  &:visited, &:link {
    color: #000;
  }
}

.vehicle-card, .vehicle-image, .vehicle-model {
    border-radius: 8px;
}

.vehicle-card {
    width: 100%;
    min-height: 150px;
    
    &:hover {
        cursor: pointer;
        @extend .bg-brand-light-gray;

        .vehicle-model {
            @extend .text-brand-text;
        }
    }

    &.preloader {
        width: 312px;
        height: 200px;
        @extend .bg-brand-light-gray;
    }

    &.border {
        border-radius: 8px;
    }

    .vehicle-model {
        @extend .text-brand-muted;
    }
}

.vehicle-image {
    width: 100%;

    &#{-default-size} {
        height: $car-image-height;
        width: $car-image-height;
    }

    img {
        max-height: $car-image-height;
        min-width: 145px;
        max-width: $car-image-width;
        width: 100%;
    }
}

.vehicle-model {
    &#{-default-size} {
        height: 30px;
        width: 100%;
    }

    font-size: 13px;
}

.logo-opacity {
    opacity: .05;
}

</style>
