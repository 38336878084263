<template>
    <div class="scroll-button-container text-white" id="scroll-buttom">
        <div
            class="d-flex justify-content-center align-items-center bg-brand-primary shadow-sm rounded-circle border border-2 toggle-active cursor-pointer scroll-button"
            @click="scrollToTop"
        >
            <Icon icon-name="navArrowDoubleUp" :icon-weight="2.3"/>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/shared/Icon.vue';

export default {
    name: "ScrollButton",
    components: {
        Icon
    },
    methods: {
        scrollToTop: function () {
            window.scrollTo(0,0);
        },
        onScroll: function () {
            window.onscroll = function() {
                var currentScrollPos = window.pageYOffset;
                const element = document.getElementById("scroll-buttom");
                if (element) {
                    if (currentScrollPos > 300) {
                        element.style.bottom = '40px';
                    } else {
                        element.style.bottom = "-100px";
                    }
                }
            }
        }
    },
    beforeMount: function () {
        this.onScroll();
    }
}
</script>

<style scoped lang="scss">
.scroll-button {
    position: absolute;
    color: white;
    border-color: transparent !important;
    opacity: .9;
    top: -20px;
    left: 1rem;
    width: 40px;
    height: 40px;
    transition: 0.3s;
    font-size: 1.3rem;

    &:hover {
        opacity: 1;
    }
}

.scroll-button-container{
    position: fixed;
    bottom: -100px;
    left: 95%;
    transition: 0.3s;
}

@media only screen and (max-width: 1280px) {
    .scroll-button-container{
        left: 93%;
    }
}

@media only screen and (max-width: 960px) {
    .scroll-button-container{
        left: 90%;
    }
}

@media only screen and (max-width: 768px) {
    .scroll-button-container{
        left: 86%;
    }
}

@media only screen and (max-width: 640px) {
    .scroll-button-container{
        left: 83%;
    }
}

@media only screen and (max-width: 576px) {
    .scroll-button-container{
        left: 80%;
    }
}
</style>
