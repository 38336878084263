<template>
  <Container navbar small :back-to="goBackTo" :message="`${makeId}`">
    <section class="col-12 text-brand-text m-0 p-0">
      <div class="container-fluid p-0 m-0">
        <div class="row justify-content-end aling-content-center m-0 bg-brand-body-secondary">
          <div
            ref="yearsCalendarContainer"
            class="col-12 col-md-12 col-lg-4 col-xl-3 m-0 p-0 d-flex flex-column justify-content-start align-items-center years-calendar-container"
          >
            <div class="h-100 w-100 d-block">
              <MakeYearSelection
                v-if="years.length > 0"
                :years="years"
                :logo="makeLogoUrl()"
                :make="makeId"
                :year-saved="yearSaved"
                @change-year="changeYear"
              />
            </div>
          </div>

          <div class="col-12 col-md-12 col-lg-8 col-xl-9 p-0 px-md-5 py-md-3 bg-white">
            <div class="w-100 py-md-0 px-3">
              <div class="container-fluid m-0 p-0 pb-3">
                <div v-if="loaded" class="row justify-content-start align-items-end m-0 p-0">
                  <div v-if="models.trucks.length > 0" class="col-12 text-center text-md-start border-bottom my-5">
                    <h5 class="fw-medium">TRUCK</h5>
                  </div>
                  <VehicleCard
                    v-for="(model, index) in models.trucks"
                    :key="index"
                    :vehicle-image="modelImageUrl(model)"
                    :vehicle-model="`${yearLabel || ''} ${model.Model}`"
                    :link="getVehicleLink(model)"
                  />

                  <div v-if="models.suvs.length > 0" class="col-12 text-center text-md-start border-bottom my-5">
                    <h5 class="fw-medium">SUV</h5>
                  </div>
                  <VehicleCard
                    v-for="(model, index) in models.suvs"
                    :key="index"
                    :vehicle-image="modelImageUrl(model)"
                    :vehicle-model="`${yearLabel || ''} ${model.Model}`"
                    :link="getVehicleLink(model)"
                  />

                  <div v-if="models.cars.length > 0" class="col-12 text-center text-md-start border-bottom my-5">
                    <h5 class="fw-medium">CAR</h5>
                  </div>
                  <VehicleCard
                    v-for="(model, index) in models.cars"
                    :key="index"
                    :vehicle-image="modelImageUrl(model)"
                    :vehicle-model="`${yearLabel || ''} ${model.Model}`"
                    :link="getVehicleLink(model)"
                  />
                  
                  <div v-if="models.vans.length > 0" class="col-12 text-center text-md-start border-bottom my-5">
                    <h5 class="fw-medium">VAN</h5>
                  </div>
                  <VehicleCard
                    v-for="(model, index) in models.vans"
                    :key="index"
                    :vehicle-image="modelImageUrl(model)"
                    :vehicle-model="`${yearLabel || ''} ${model.Model}`"
                    :link="getVehicleLink(model)"
                  />
                  
                  <div v-if="models.utvs.length > 0" class="col-12 text-center text-md-start border-bottom my-5">
                    <h5 class="fw-medium">UTVs</h5>
                  </div>
                  <VehicleCard
                    v-for="(model, index) in models.utvs"
                    :key="index"
                    :vehicle-image="modelImageUrl(model)"
                    :vehicle-model="`${yearLabel || ''} ${model.Model}`"
                    :link="getVehicleLink(model)"
                  />
                </div>
                <div v-else class="row justify-content-start align-items-end m-0 p-0">
                  <div class="col-12 text-center text-md-start border-bottom my-5">
                    <h5 class="fw-medium text-white">Loading</h5>
                  </div>
                  <VehicleCard
                    v-for="(model, index) in models"
                    :key="index"
                    :vehicle-image="null"
                    :vehicle-model="null"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollButton/>
      </div>
    </section>
  </Container>
</template>

<script>
import Container from "@/components/Container.vue";
import VehicleCard from "@/components/vehicles/VehicleCard.vue";
import MakeYearSelection from "@/components/vehicles/MakeYearSelection.vue";
import ScrollButton from "@/components/shared/ScrollButton.vue";
import apiRequest from "@/utils/apiRequest";

export default {
  name: "VehiclesMake",
  components: {
    Container,
    VehicleCard,
    MakeYearSelection,
    ScrollButton
  },
  data: function() {
    return {
        years: [],
        yearLabel: null,
        yearSaved: null,
        modelURL: null,
        logoBaseUrl: null,
        models: new Array(12).fill(),
        make: null,
        makes: null,
        loaded: false,
        yearsContainerTopPosition: null,
        yearsConteinerEventFunction: null
    }
  },
  computed: {
    makeId: function () {
      return this.$route.params.makeId;
    },
    goBackTo: function () {
      if (this.makes) {
        if (this.makes.length === 1) {
          return '/home';
        }
      }
      return '/vehicles';
    }
  },
  methods: {
    findMake: function(makeId) {
      const getMake = (make) => make.Make === makeId;
      this.makes = this.$store.getters.vehiclesMakes.makes;
      return this.makes.filter(getMake);
    },
    changeYear: async function (data) {
      const year = data.value;
      const yearId = data.id;

      this.models = [null];
      this.loaded = false;
      
      const models = await this.getModels(year);
      if (models) {
        this.modelURL = models.ImgUrlBase;
      }

      const cars = models.Models.filter( model => model.Type.toUpperCase() === 'CAR');
      const trucks = models.Models.filter( model => model.Type.toUpperCase() === 'TRUCK');
      const suvs = models.Models.filter( model => model.Type.toUpperCase() === 'SUV');
      const vans = models.Models.filter( model => model.Type.toUpperCase() === 'VAN');
      const utvs = models.Models.filter( model => model.Type.toUpperCase() === 'UTV');

      this.models = {
        cars: cars ? cars:[],
        trucks: trucks ? trucks:[],
        suvs: suvs ? suvs:[],
        vans: vans ? vans:[],
        utvs: utvs ? utvs:[]
      };

      this.yearSaved = `${year}`;
      this.loaded = true;
      
      const element = document.getElementById(`year${yearId}`);
      if (element) {
        element.focus();
      }
    },
    getModels: async function(year) {
      const vehicleThumbnails = this.$store.getters.clientSettings.vehicleThumbnails;
      const colorFilter = vehicleThumbnails == 'mono' ? `&f-imgColor=${vehicleThumbnails}`:'';
      let request = `/vehicles/models?f-make=${encodeURIComponent(this.makeId)}${colorFilter}&f-types=Truck,Van,Car,SUV&i-type&i-img032=350&i-submodelCount&p-size=500`;

      if (year) {
        this.yearLabel = year;
        request += `&f-year=${year}`;
      }

      return await apiRequest(request);
    },
    getBuilderLink: function (model) {
      return `/builder?vehicle_make=${model.Make}&vehicle_model=${encodeURIComponent(model.Model)}${this.yearLabel ? '&vehicle_year='+this.yearLabel:''}`;      
    },
    getVehicleLink: function (model) {
      return this.getBuilderLink(model);
    },
    makeLogoUrl: function () {
      const logo = this.make.Logo || null;
      
      if (logo) {
        return `${this.logoBaseUrl}${this.make.Logo}`;
      }
      return null;
    },
    modelImageUrl: function (model) {
      const modelData = model || null;
      
      if (modelData) {
        if (modelData.Img032['350']) {
          return `${this.modelURL}${modelData.Img032['350']}`;
        }
      }

      return 'https://vvs.autosyncstudio.com/static/not-available-350.svg';
    }
  },
  beforeMount: async function() {
    if (!('baseUrl' in this.$store.getters.vehiclesMakes)) {
      let makes = await apiRequest('/vehicles/makes?f-types=Truck,Van,Car,SUV&i-logos&p-size=500');
      this.$store.commit('vehiclesMakes', {baseUrl: makes.LogoUrlBase || null, makes: makes.Makes || null});
    }
    
    this.make = this.findMake(this.makeId)[0];
    this.logoBaseUrl = this.$store.getters.vehiclesMakes.baseUrl;

    let years = await apiRequest(`/vehicles/years?f-make=${encodeURIComponent(this.makeId)}&p-size=500`);
    this.years = years.Years;

    if (this.$store.getters.vehicleBuilding.year) {
      this.yearSaved = this.$store.getters.vehicleBuilding.year;
    }
  },
  mounted: function () {
    const element = this.$refs.yearsCalendarContainer;
    this.yearsContainerTopPosition = element.getBoundingClientRect().top;
    this.yearsConteinerEventFunction = (() => {
      if (window.scrollY > this.yearsContainerTopPosition) {
        element.style.top = 0;
      } else {
        element.style.top = `${this.yearsContainerTopPosition}px`;
      }
    });

    window.addEventListener('scroll', this.yearsConteinerEventFunction);
  },
  unmounted: function () {
    window.removeEventListener('scroll', this.yearsConteinerEventFunction);
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/exportable.scss";

.years-calendar-container {
  transition: top .3s ease-out;
  -o-transition: top .3s ease-out;
  -moz-transition: top .3s ease-out;
  -webkit-transition: top .3s ease-out;
}
@media only screen and (min-width: 1920px) {
  .years-calendar-container {
    position: fixed;
    top: $navbar-height/1.4;
    left: calc((100% - 1920px) / 2);
    max-width: 1920px * 0.25;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1919px) {
  .years-calendar-container {
    position: fixed;
    top: $navbar-height/1.4;
    left: 0;
  }
}
</style>
