<template>
    <div class="bg-brand-body-secondary rounded pb-2 py-lg-3 h-100">
        <div class="d-flex justify-content-center m-lg-4">
            <div class="col-3 col-lg-12 text-center">
                <img :src="logo" :alt="`Logo ${make}`" class="make-logo my-3 my-lg-1">
            </div>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center">
            <div class="text-center bg-brand-light-gray w-100">
                <h3 class="year-value my-0 py-2 font-family-secondary text-brand-gray">{{selectedYear}}</h3>
            </div>
            <div class="d-block d-lg-none container m-0 p-0 py-2 py-lg-3 py-lg-0 pb-lg-3">
                <div class="row m-0 p-0">
                    <div
                        :class="[
                            'col-lg-12 mt-1',
                            collapsed ? 'calendar-years-grid-collapsed':'calendar-years-grid-expanded'
                        ]"
                    >
                        <div class="row mx-1 mx-sm-3">
                            <div
                                v-for="(year, index) in yearsItems"
                                :key="index"
                                class="col-4 col-xxl-3 px-2 py-3 text-center"
                            >
                                <input
                                    :id="`year${index}`"
                                    :class="[
                                        'border-0 rounded-pill px-3 py-1 bg-brand-body-secondary',
                                        'calendar-years-item',
                                        selectedYear == year && 'bg-brand-primary text-white'
                                    ]"
                                    type="button"
                                    :value="year"
                                    @click="changeYear(year)"
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2">
                        <div class="d-flex justify-content-center align-items-center arrow-button text-muted position-relative mx-auto cursor-pointer" @click="toggleCalendar">
                            <Icon v-if="collapsed" icon-name="navArrowDoubleDown" :icon-weight="2.3"/>
                            <Icon v-if="!collapsed" icon-name="navArrowDoubleUp" :icon-weight="2.3"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-none d-lg-block container m-0 p-0 py-2 py-lg-3 py-lg-0 pb-lg-3">
                <div class="row m-0 p-0">
                    <div class="col-lg-12 mt-1 calendar-years-grid-expanded">
                        <div class="row mx-1 mx-sm-3">
                            <div
                                v-for="(year, index) in yearsItems"
                                :key="index"
                                class="col-4 col-xxl-3 px-2 py-3 text-center"
                            >
                                <input
                                    :id="`year${index}`"
                                    :class="[
                                        'border-0 rounded-pill px-3 py-1 bg-brand-body-secondary',
                                        'calendar-years-item',
                                        selectedYear == year && 'bg-brand-primary text-white'
                                    ]"
                                    type="button"
                                    :value="year"
                                    @click="changeYear(year)"
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/shared/Icon.vue';

export default {
    name: 'MakeYearSelection',
    components: {
        Icon
    },
    emits: ['changeYear'],
    data: function () {
        return {
            selectedYear: null,
            selectedYearIdCalendar: null,
            yearsItems: [],
            collapsed: true
        }
    },
    props: {
        logo: {
            type: String,
            default: ''
        },
        make: {
            type: String,
            default: ''
        },
        years: {
            type: Array,
            required: true
        },
        yearSaved: {
            type: String,
            default: null,
            required: false
        }
    },
    methods: {
        getLastYear: function () {
            const yearsList = this.yearsItems;
            const current = new Date();
            const currentYear = current.getFullYear();
            
            let idLastYearCalendar = Object.keys(yearsList).find(key => yearsList[key] === currentYear - 1);

            if (idLastYearCalendar) {
                return yearsList[idLastYearCalendar];
            }
            
            return yearsList[0];
        },
        findSelectedYearId: function (year) {
            const years = this.yearsItems;
            const selectedYearId = Object.keys(years).find(key => years[key] === parseInt(year));
            return selectedYearId;
        },
        emitValues: function () {
            this.$emit('changeYear', {value: this.selectedYear, id: this.selectedYearIdCalendar});
        },
        resetFoucs: function (selectedYearId) {
            const focusId = selectedYearId == 0 ? 1:0;
            const element = document.getElementById(`year${focusId}`);
            if (element) {
                element.focus();
            }
        },
        changeYear: function (year) {
            const selectedYearId = this.findSelectedYearId(year);
            this.collapsed = true;
            this.selectedYear = year;
            this.changeSelectedItem(selectedYearId);
            this.resetFoucs(selectedYearId);
            this.emitValues();
        },
        changeSelectedItem: function (yearId) {
            this.selectedYearIdCalendar = parseInt(yearId);
        },
        toggleCalendar: function () {
            this.collapsed = !this.collapsed;

            if (this.collapsed) {
                this.emitValues();
            }
        }
    },
    mounted: function () {
        this.yearsItems = [...this.years];
        this.yearsItems.sort().reverse();

        if (this.yearSaved) {
            this.selectedYear = this.yearSaved;
        } else {
            this.selectedYear = this.getLastYear();
        }

        this.changeSelectedItem(this.findSelectedYearId(this.selectedYear));
        this.emitValues();
    }
}
</script>

<style scoped lang="scss">
.arrow-button {
    background-color: rgba($color: #000000, $alpha: .15);
    border-radius: 32px;
    font-size: 22px;
    height: 32px;
    width: 32px;

    &:hover {
        color: black !important;
        background-color: rgba($color: #000000, $alpha: .20);
    }
}

.make-logo {
    max-height: 80px;
    max-width: 100px;
}

.calendar-years-grid-expanded {
    height: auto;
}

.calendar-years-grid-collapsed {
    height: 32px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;

    &::-webkit-scrollbar {
        display: none;
    }

    .calendar-years-item {
        scroll-snap-align: start;
    }
}
</style>
